import React from "react";
import Section from "@/components/ui/section";
import Image from "next/image";
import KnowMore from "@/components/ui/know-more";
// import { useSsrCompatible } from "@/utils/use-ssr-compatible";
// import { useWindowSize } from "@/utils/use-window-size";
import useScreenSize from "@/utils/use-screen-size";
export default function About() {
  const { width } = useScreenSize();
  return (
    <Section>
      <div className="flex lg:flex-row flex-col w-full lg:gap-20 md:gap-12 gap-6 items-center">
        <div className="shrink-0 group flex justify-start items-start relative overflow-hidden lg:w-[550px] md:w-[500px] w-full lg:h-[550] md:h-[500px] h-[300px]">
          <Image
            src="/images/home/about-banner.jpg"
            alt="about banner"
            fill
            priority={true}
            quality={70}
            className="object-contain object-top"
            sizes="(max-width: 479px) 479px,(max-width: 768px) 768px, (max-width: 1200px) 1200px,(max-width: 1920px) 1920px, 1000px"
          />
        </div>
        <div className="flex flex-col w-full gap-3">
          <h1 className="text-blue lg:text-[40px] md:text-[36px] text-[26px]">
            People’s Courage International
          </h1>
          {/* <p className="lg:text-lg text-base text-lightBlue">
            People’s Courage International is a USA-based not-for-profit
            organization registered under section 501(c) of the Internal Revenue
            Code (IRC).
          </p> */}
          <p className="text-justify">
            PCI promotes initiatives that work deeply – and at scale – on the
            ground and leverage this to change systems. To ensure the success of
            our initiatives, we support grassroots leaders and organizations by
            bringing resources, technical capacity, research, technology, and
            other supports so they can better realize their impact potential
          </p>
          {/* <ul className="list-none flex flex-col gap-2 pl-0 md:text-base text-sm">
            <li>(1) Safe migration and worker protection</li>
            <li>(2) Global Climate Resiliance Collective</li>
            <li>
              (3) Building resilience in the not-for-profit organizations in the
              region.
            </li>
          </ul> */}
          <KnowMore
            path={"/who-we-are"}
            text={"Read More"}
            variant="darkBlue"
          />
        </div>
      </div>
    </Section>
  );
}
