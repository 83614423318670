import React from "react";
import Section from "@/components/ui/section";
import Image from "next/image";
import KnowMore from "@/components/ui/know-more";
export default function WhatWeDo() {
  const data = [
    {
      title: "Migrants Resilience Collaborative",
      description:
        "To address the underlying systemic issues, People’s Courage International established the...",
      buttonLink: "/migrants-resilience-collaborative/",
      image: "/images/home/mrc-banner.jpg",
      gif: "/images/home/MRC_edited.gif",
    },
    {
      title: "Global Climate Resilience Collective",
      description:
        "The mission of our initiative is to build the resilience of climate-affected informal workers by ensuring their access to climate-responsive social protection and adaptation supports.",
      buttonLink: "/global-climate-resilience-collective/",
      image: "/images/home/CRI DIALOGUE BOX.webp",
      gif: "/images/home/PVAWC-Edited.gif",
    },
    {
      title: "Grassroots Resilience Institute",
      description:
        "The Grassroots Resilience Institute (GRI) seeks to partner with CBOs and help solve for the specific challenges our partners face in sustaining...",
      buttonLink: "/grassroots-resilience-institute/",
      image: "/images/home/gri-banner.jpg",
      gif: "/images/home/GRI-Edited.gif",
    },
  ];
  return (
    <Section variant="flatZero">
      <h2 className="text-blue text-center">What we do</h2>
      <div className="lg:grid lg:grid-cols-3 flex flex-col w-full md:gap-8 gap-10 mt-6">
        {data?.map((row, key) => (
          <Block data={row} key={"what-we-do" + key} />
        ))}
      </div>
    </Section>
  );
}
const Block = ({ data }) => {
  const { title, image, description, buttonLink, gif } = data;
  return (
    <div
      //   href={buttonLink}
      className="flex flex-col w-full rounded-xl overflow-hidden h-full bg-[#DFDFDF] hover:shadow-artist transition-all duration-300 ease lg:hover:scale-[1.05]"
    >
      <div className="shrink-0 group flex justify-start items-start relative overflow-hidden lg:h-[230px] md:h-[330px] h-[200px] w-full">
        <Image
          src={image}
          alt={title}
          fill
          className="object-cover object-center"
          sizes="(max-width: 479px) 479px,(max-width: 768px) 768px, (max-width: 1200px) 1200px,(max-width: 1920px) 1920px, 1000px"
        />
      </div>
      <div className="flex flex-row w-full h-full gap-4 lg:p-4 md:px-4 md:py-8 p-4">
        <div className="shrink-0 group flex justify-start items-start relative overflow-hidden lg:h-[100px] md:h-[100px] h-[100px] w-[100px]">
          <Image
            src={gif}
            alt={title}
            fill
            className="object-contain object-top"
            sizes="(max-width: 479px) 479px,(max-width: 768px) 768px, (max-width: 1200px) 1200px,(max-width: 1920px) 1920px, 1000px"
          />
        </div>
        <div className="flex flex-col w-full h-full gap-2">
          <div className="text-blue font-bold !leading-tight lg:text-[20px] md:text-2xl text-base">
            {title}
          </div>
          <p className="text-text mb-2 text-justify">{description}</p>

          <KnowMore
            path={buttonLink}
            text={"Read More"}
            variant="darkBlue"
            className="mt-auto"
          />
        </div>
      </div>
    </div>
  );
};
